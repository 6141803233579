import { createRouter, createWebHistory } from "vue-router";
import routeBuilder from "@/shared/router/routeBuilder";
import redirectToLoginIfUnauthorised from "@/shared/router/guards/redirectToLoginIfUnauthorised";
import redirectToHomeIfAuthorised from "@/shared/router/guards/redirectToHomeIfAuthorised";

const routes = [
  {
    path: "/",
    component: () => import("@/shared/layouts/default/DefaultLayout.vue"),
    children: [
      {
        path: "/",
        redirect: { name: "auth.login" },
      },
      ...routeBuilder("default"),
    ],
    beforeEnter: redirectToLoginIfUnauthorised,
  },
  {
    path: "/",
    component: () => import("@/shared/layouts/unauthorised/UnauthorisedLayout.vue"),
    children: [
      {
        path: "/",
        redirect: { name: "auth.login" },
      },
      ...routeBuilder("unauthorised"),
    ],
    beforeEnter: redirectToHomeIfAuthorised,
  },
  {
    path: "/",
    component: () => import("@/shared/layouts/unauthorised/UnauthorisedLayout.vue"),
    children: [
      {
        path: "/",
        redirect: { name: "auth.login" },
      },
      ...routeBuilder("both"),
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "errors.notfound",
    component: () => import("@/shared/views/NotFoundView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.onError((error, to) => {
  if (
    error.message.includes("Failed to fetch dynamically imported module") ||
    error.message.includes("Importing a module script failed")
  ) {
    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location.href = to.fullPath;
    }
  }
});

export default router;
