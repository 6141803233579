import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

window.addEventListener("storage", (event) => {
  if (!event.key || !event.newValue) return;

  const key = event.key;
  const newValue = JSON.parse(event.newValue);

  // @ts-ignore
  const store = pinia._s.get(key);
  if (store) {
    store.$patch(newValue);
  }
});

export default pinia;

