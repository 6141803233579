import { Router } from "vue-router";
import { App } from "vue";
import * as Sentry from "@sentry/vue";

export default function initSentry (app: App, router: Router) {
  if (!import.meta.env.VITE_SENTRY_DSN || !import.meta.env.VITE_SENTRY_ENVIRONMENT) return;
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    beforeSend (event, hint) {
      if (hint && hint.originalException && (hint.originalException as any).isHandled === true) {
        return null;
      }
      return event;
    },
    tracesSampleRate: 1.0,
    tracePropagationTargets: [new RegExp(`^${window.location.origin}/api(.*)`)],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}
