import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import { useAuthorisationStore } from "@/modules/authorisation/authorisationStore";

export const userManager = new UserManager({
  authority: import.meta.env.VITE_KINDE_AUTHORITY,
  client_id: import.meta.env.VITE_KINDE_CLIENT_ID,
  redirect_uri: import.meta.env.VITE_KINDE_REDIRECT_URI,
  scope: import.meta.env.VITE_KINDE_SCOPE,
  silent_redirect_uri: import.meta.env.VITE_KINDE_REDIRECT_URI,
  automaticSilentRenew: true,
  extraQueryParams: { audience: "https://api.debitroom" },
  userStore: new WebStorageStateStore({ store: window.localStorage }),
});

userManager.events.addUserLoaded((user) => {
  const authorisationStore = useAuthorisationStore();
  authorisationStore.afterLogin(user);
});

userManager.events.addUserSignedOut(() => {
  console.warn("User signed out");
  userManager.removeUser(); // Clear local state
});

userManager.events.addUserUnloaded(() => {
  const authorisationStore = useAuthorisationStore();
  authorisationStore.accessToken = "";
  authorisationStore.user = undefined;
});
