import vuetify from "./vuetify";
import pinia from "./pinia";
import i18n from "./i18n";
import router from "../router/index";
import VuetifyCrud from "@debitroom-software/vuetify-crud";
import "@debitroom-software/vuetify-crud/dist/style.css";
import type { App } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import vuetifyAlert from "@debitroom-software/vuetify-alert";
import useScopedTranslations from "@/shared/plugins/i18n/composables/useScopedTranslations";
import { post } from "@/shared/api/apiInstance";
import initSentry from "@/shared/plugins/sentry";
import { useSnackbar } from "@debitroom-software/vuetify-snackbar-queue";

// @ts-ignore for some reason, this is to complex for ts to compute
const { t } = i18n.global;

export function registerPlugins (app: App) {
  initSentry(app, router);
  app.
    use(vuetify).
    use(router).
    use(pinia).
    use(i18n).
    use(vuetifyAlert, { closeBtnText: t("global.actions.close") }).
    component("FontAwesomeIcon", FontAwesomeIcon);

  app.use(VuetifyCrud, {
    useScopedTranslations,
    useSnackbar,
    apiInstance: { post },
  });
}
