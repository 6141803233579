import { defineStore } from "pinia";
import { User } from "oidc-client-ts";
import { jwtDecode } from "jwt-decode";
import { Permission, Role, Token } from "./types";
import { userManager } from "@/modules/authorisation/userManager";
import router from "@/shared/router";
import getProfile from "@/modules/authorisation/api/getProfile";
import { Profile, Agreements } from "@/shared/types";
import dayjs from "@/shared/plugins/dayjs";
import { isAxiosError } from "axios";


export const useAuthorisationStore = defineStore({
  id: "authorisation",
  persist: true,
  state: (): {
    // Take in mind, that everything new added to tist list, should be optional. Since this state is persistend.
    accessToken: string;
    user?: {
      email?: string;
      name?: string;
      permissions?: Permission[];
      roles?: Role[];
      featureFlags?: { [key: string]: boolean };
    };
    profile?: Profile;
    profileLastFetchedAt?: string;
    userPreferences?: Record<string, any>;
    agreements?: Agreements;
    hasErrorBecauseOfNoPartnerAccount: boolean;
  } => ({
    accessToken: "",
    user: undefined,
    userPreferences: { isDarkMode: null },
    profile: undefined,
    profileLastFetchedAt: undefined,
    agreements: undefined,
    hasErrorBecauseOfNoPartnerAccount: false,
  }),
  actions: {
    afterLogin (user: User) {
      this.accessToken = user.access_token;
      const payload = jwtDecode<Token>(this.accessToken);
      this.user = {
        email: user.profile.email,
        name: user.profile.name,
        permissions: payload.permissions,
        roles: payload.roles,
        featureFlags: Object.entries(payload?.feature_flags || {}).map(([key, value]) => ({ [key]: value.v })).
          reduce((acc, cur) => ({
            ...acc,
            ...cur,
          }), {}),
      };
      this.user.name = user.profile.name;
    },
    async signIn () {
      const user = await userManager.getUser();
      if (user && user.refresh_token) {
        try {
          return await userManager.signinSilent().
            then(() => router.push({ name: "dashboard" }));
        } catch (error) {
          return await userManager.signinRedirect();
        }
      } else {
        return await userManager.signinRedirect();
      }
    },
    async signOut () {
      this.accessToken = "";
      localStorage.clear();
      return userManager.signoutRedirect(
        { post_logout_redirect_uri: window.location.origin + router.resolve({ name: "auth.login" }).href },
      );
    },
    setUserPreference (key: string, value: any) {
      if (!this.userPreferences) {
        this.userPreferences = {};
      }
      this.userPreferences[key] = value;
    },
    async fetchProfileInformation (force: boolean = false) {
      if ((this.profileLastFetchedAt && dayjs().diff(this.profileLastFetchedAt, "minutes") < 5) && !force) return;
      try {
        this.profileLastFetchedAt = dayjs().toISOString();
        const response = await getProfile();
        this.profile = response.data.profile;
        this.agreements = response.data.agreements;
      } catch (error) {
        this.profileLastFetchedAt = undefined;
        if (isAxiosError(error) && error.response?.status === 403) {
          this.hasErrorBecauseOfNoPartnerAccount = true;
          return;
        }
        throw error;
      }
    },
  },
  getters: {
    hasFeature (state) {
      return (flag: string) => state.user?.featureFlags?.[flag] ?? false;
    },
    hasRole (state) {
      return (role: string) => state.user?.roles?.some(({ key }) => key === role) ?? false;
    },
  },
});
